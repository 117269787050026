import React from "react";
import Layout from "../components/layout";
import Slideshow from "../components/slideshow";

import "../styles/w3.css";

const DesignPage = () => (
	<Layout title="Design">
		<h1 className={"w3-center"}>Page is a Work in Progress!</h1>
		<div
			className={"mobile-width centered w3-margin-top index w3-padding-large"}
		>
			<Slideshow />
		</div>
	</Layout>
);

export default DesignPage;
