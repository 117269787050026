import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import "../styles/w3.css";

function Slideshow() {
	const [index, setIndex] = useState(0);
	const { allFile } = useStaticQuery(
		graphql`
			query {
				allFile(
					sort: { fields: name, order: DESC }
					filter: { relativeDirectory: { eq: "designs" } }
				) {
					edges {
						node {
							id
							name
							childImageSharp {
								fluid(maxWidth: 600) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		`
	);
	//Minus 1 for array offset from 0
	const length = allFile.edges.length - 1;
	const handleNext = () =>
		index === length ? setIndex(0) : setIndex(index + 1);
	const handlePrevious = () =>
		index === 0 ? setIndex(length) : setIndex(index - 1);
	const { node } = allFile.edges[index];
	return (
		<div>
			<div>
				<Img
					fluid={node.childImageSharp.fluid}
					key={node.id}
					alt={node.name.replace(/-/g, " ").substring(2)}
				/>
			</div>
			<div>
				<br />
				<button
					onClick={() => handlePrevious()}
					className={"w3-button w3-left w3-opacity-min w3-gray"}
				>
					&#10094;
				</button>
				{/* <div
					className={
						"w3-center w3-container w3-section w3-large w3-text-white w3-display-bottommiddle"
					}
					style={{ width: `100%` }}
				>
					<span
						className={"w3-badge w3-border w3-transparent w3-hover-white"}
						onClick={() => setIndex(0)}
					></span>
					<span
						className={"w3-badge w3-border w3-transparent w3-hover-white"}
						onClick={() => setIndex(1)}
					></span>
					<span
						className={"w3-badge w3-border w3-transparent w3-hover-white"}
						onClick={() => setIndex(2)}
					></span>
					<span
						className={"w3-badge w3-border w3-transparent w3-hover-white"}
						onClick={() => setIndex(3)}
					></span>
					<span
						className={"w3-badge w3-border w3-transparent w3-hover-white"}
						onClick={() => setIndex(4)}
					></span>
				</div> */}
				<button
					onClick={() => handleNext()}
					className={"w3-button w3-right w3-opacity-min w3-gray"}
				>
					&#10095;
				</button>
				<br />
				<br />
			</div>
		</div>
	);
}
export default Slideshow;
